(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/form-button/views/form-button.js') >= 0) return;  svs.modules.push('/components/banner_actions/form-button/views/form-button.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_actions=_svs.banner_actions||{};
_svs.banner_actions.form_button=_svs.banner_actions.form_button||{};
_svs.banner_actions.form_button.templates=_svs.banner_actions.form_button.templates||{};
svs.banner_actions.form_button.templates.form_button = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "   "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"linkTitle") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-form w100 u-flex u-flex-justify-content-center u-flex-row\" data-form-link-form=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"formLinkForm") : stack1), depth0))
    + "\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"title":((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"linkTitle") : stack1),"target":((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"linkTarget") : stack1),"href":((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"linkUrl") : stack1),"transparent":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"viewConfig") : stack1)) != null ? lookupProperty(stack1,"transparent") : stack1),"inverted":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"viewConfig") : stack1)) != null ? lookupProperty(stack1,"inverted") : stack1),"focus":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"viewConfig") : stack1)) != null ? lookupProperty(stack1,"focus") : stack1),"data-click-tracking":(depth0 != null ? lookupProperty(depth0,"formTrackingString") : depth0),"block":(lookupProperty(helpers,"setDefault")||(depth0 && lookupProperty(depth0,"setDefault"))||alias2).call(alias1,true,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"viewConfig") : stack1)) != null ? lookupProperty(stack1,"block") : stack1),{"name":"setDefault","hash":{},"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":51}}}),"class":"js-form-btn"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":14,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
Handlebars.partials['components-banner_actions-form_button-form_button'] = svs.banner_actions.form_button.templates.form_button;
})(svs, Handlebars);


 })(window);